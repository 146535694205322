import { Module } from 'vuex'
import RootState from 'core/types/RootState'
import { CookiePopupState } from '../types/CookiePopupState'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const gdprCookiePopupModule: Module<CookiePopupState, RootState> = {
  namespaced: true,
  state: {
    cookiePopupState: []
  }
}
